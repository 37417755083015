const loadState = (key) => {
  try {
    const serializedState = localStorage.getItem(key);

    if (serializedState === null) {
      return undefined;
    }

    return JSON.parse(serializedState);
  } catch (error) {
    return undefined;
  }
};

const saveState = (key, state) => {
  try {
    const serializedState = JSON.stringify(state);

    localStorage.setItem(key, serializedState);
  } catch (error) {
    return error;
  }
};

const removeState = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    return error;
  }
};

const saveOnSession = (key, state) => {
  const serializedState = JSON.stringify(state);

  localStorage.setItem(key, serializedState);
};

const getFromSession = (key) => {
  const serializedState = localStorage.getItem(key);

  return JSON.parse(serializedState);
};

export default {
  loadState,
  saveState,
  removeState,
  saveOnSession,
  getFromSession,
};
