<template>
  <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
    <v-app-bar app color="primary" dark>
      <div class="d-flex align-center">
        <h1>
          AILA
          <span class="text-h6 font-italic hidden-sm-and-down">
            Artificial Intelligence for Learning Assistance
          </span>
        </h1>
      </div>

      <v-spacer></v-spacer>

      <div v-if="authRouter" class="mr-2 hidden-sm-and-down">
        <v-btn
          text
          class="mx-1"
          v-for="(router, i) in menu.routers"
          :key="i"
          :to="router.path"
        >
          {{ router.title }}
        </v-btn>
      </div>

      <v-menu offset-y transition="scroll-y-transition">
        <template v-slot:activator="{ on, attrs }">
          <v-btn light v-bind="attrs" v-on="on">
            {{ menu.title }}
            <v-icon right>{{ menu.icon }}</v-icon>
          </v-btn>
        </template>

        <v-list nav dense>
          <v-list-item @click="$vuetify.theme.dark = !$vuetify.theme.dark">
            <v-list-item-title>Tema</v-list-item-title>
            <v-list-item-icon>
              <v-icon>
                {{
                  $vuetify.theme.dark
                    ? "mdi-weather-night"
                    : "mdi-white-balance-sunny"
                }}
              </v-icon>
            </v-list-item-icon>
          </v-list-item>

          <template v-if="authRouter">
            <v-divider></v-divider>

            <v-list-item
              class="hidden-sm-and-up"
              link
              v-for="(router, i) in menu.routers"
              :key="i"
              :to="router.path"
            >
              <v-list-item-title>{{ router.title }}</v-list-item-title>
            </v-list-item>

            <v-divider></v-divider>

            <v-list-item link :to="menu.logout.path">
              <v-list-item-title>{{ menu.logout.title }}</v-list-item-title>
              <v-list-item-icon>
                <v-icon>{{ menu.logout.icon }}</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </template>
        </v-list>
      </v-menu>
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>

    <v-footer padless color="primary" dark>
      <v-col class="d-sm-flex" cols="6">
        <v-tooltip top>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small
              text
              dark
              v-on="on"
              v-bind="attrs"
              href="https://forms.office.com/r/7WHp22e8yf"
              target="_blank"
            >
              Suporte
              <v-icon small right>mdi-face-agent</v-icon>
            </v-btn>
          </template>
          <span>Caso tenha dificuldade de acesso, entre em contato!</span>
        </v-tooltip>

        <p class="ma-0 ml-2">versão {{ versionProject }}</p>
      </v-col>
      <v-col class="d-sm-flex justify-sm-end" cols="6">
        <v-img
          contain
          max-height="30"
          max-width="150"
          src="./assets/images/anima/labhub.png"
        ></v-img>
        <v-img
          :class="{ 'ml-auto': $vuetify.breakpoint.smAndDown }"
          contain
          max-height="36"
          max-width="100"
          src="./assets/images/anima/logo.png"
        ></v-img>
      </v-col>
    </v-footer>
  </v-app>
</template>

<script>
import packageJson from "../package.json";
export default {
  data() {
    return {
      menu: {
        title: "Menu",
        icon: "mdi-menu",
        routers: [
          { title: "Início", path: "/forms" },
          { title: "Portfólio", path: "/recommendations" },
          {
            title: "Habilidades básicas",
            path: "/performance-recommendations",
          },
        ],
        logout: { title: "Sair", icon: "mdi-logout", path: "/logout" },
      },
    };
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
    authRouter() {
      return this.$route.meta.requiresAuth;
    },
    versionProject() {
      return packageJson?.version;
    },
  },
};
</script>

<style>
body {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  body {
    font-size: 16px;
  }
}

.f-height {
  height: calc(100vh - 55px - 50px);
}
.img-height {
  height: calc(100vh - 55px - 80px) !important;
}
</style>
