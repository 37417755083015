const routes = [
  {
    path: "/recommendations",
    name: "recommendations",
    component: () => import("@/views/RecommendationsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/performance-recommendations",
    name: "performance-recommendations",
    component: () => import("@/views/PerformanceRecommendationsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/forms",
    name: "forms",
    component: () => import("@/views/FormsView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/forms/:formId",
    name: "form",
    component: () => import("@/views/FormView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/chat/:chatId",
    name: "chat",
    component: () => import("@/views/ChatView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/exams/:examId",
    name: "exam",
    component: () => import("@/views/ExamView.vue"),
    meta: {
      requiresAuth: true,
    },
  },
];

export default routes;
