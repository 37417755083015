import StorageHelper from "@/helpers/storage";

const routes = [
  { path: "/", name: "home", component: () => import("@/views/HomeView.vue") },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/LoginView.vue"),
  },
  {
    path: "/logout",
    name: "logout",
    beforeEnter(to, from, next) {
      StorageHelper.removeState("user");
      StorageHelper.removeState("tokens");
      StorageHelper.removeState("recommendations");
      StorageHelper.removeState("exam-answers");
      return next((window.location.href = "/"));
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("@/views/AboutView.vue"),
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/RegisterView.vue"),
  },
  {
    path: "/update",
    name: "update",
    component: () => import("@/views/UpdateView.vue"),
  },
  {
    path: "/parceria",
    name: "parceria",
    component: () => import("@/views/ParceriaView.vue"),
  },
];

export default routes;
