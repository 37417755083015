import Vue from "vue";
import Vuetify, { VSnackbar, VBtn, VIcon } from "vuetify/lib";

//import colors from "vuetify/lib/util/colors";

Vue.use(Vuetify, {
  components: {
    VSnackbar,
    VBtn,
    VIcon,
  },
});

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#5B2D82",
        secondary: "#42B4E4",
        background: "#ECECEC",
      },
      dark: {
        primary: "#5B2D82",
        secondary: "#42B4E4",
        background: "#121212",
      },
    },
  },
});
