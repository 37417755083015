import Vue from "vue";
import VueRouter from "vue-router";
import BaseRoutes from "@/router/BaseRoutes";
import AuthRoutes from "@/router/AuthRoutes";

import StorageHelper from "@/helpers/storage";

Vue.use(VueRouter);

const routes = [];

routes.push(...BaseRoutes);
routes.push(...AuthRoutes);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const tokens = StorageHelper.loadState("tokens");
  const user = StorageHelper.loadState("user");
  const isAuthenticated = tokens && user;
  if (
    to.matched.some((record) => record.meta.requiresAuth) &&
    !isAuthenticated
  ) {
    next("/login");
  } else {
    next();
  }
});

export default router;
